import _ from 'lodash';
import Link from 'next/link';
import React from 'react';
import {
  FOOTER_DESTINATIONS,
  FOOTER_HELPFUL_LINKS,
  FOOTER_SOCIALS,
  FOOTER_WORK_WITH_US,
} from '../constants';
import { compressHashCodeUserProfile } from '../helpers';
import { ChevronUp } from 'react-feather';
import Image from 'next/image';

export default function Footer() {
  const handleRedirect = (item) => {
    const authorSlug = compressHashCodeUserProfile();

    window.location.href =
      process.env.NEXT_PUBLIC_PROFILE_URL + item.slug + authorSlug;
  };

  return (
    <React.Fragment>
      <footer
        id="pageFooter"
        className="container-fluid pt-md-5 pb-md-4 pt-4 pb-4"
      >
        <div
          id="scrollToTopBtn"
          className="scroll-top-btn"
          title="Scroll To Top"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
        >
          <ChevronUp />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2 ftr-snippet">
              <h4>Destinations</h4>
              <ul className="list-unstyled">
                {_.map(FOOTER_DESTINATIONS, (item) => (
                  <li key={item.id}>
                    <Link href={item.slug}>
                      <a>{item.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-3 ftr-snippet">
              <h4>Helpful Links</h4>
              <ul className="list-unstyled">
                {_.map(FOOTER_HELPFUL_LINKS, (item) => (
                  <li key={item.id}>
                    <a onClick={() => handleRedirect(item)}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-2 ftr-snippet pr-0">
              <h4>Work With Us</h4>
              <ul className="list-unstyled">
                {_.map(FOOTER_WORK_WITH_US, (item) => (
                  <li key={item.id}>
                    <a onClick={() => handleRedirect(item)}>{item.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-2 ftr-snippet">
              <h4>Follow Us</h4>
              <ul className="list-unstyled ss-icon">
                {_.map(FOOTER_SOCIALS, (item) => (
                  <li key={item.id}>
                    <a target="_blank" href={item.slug} rel="noreferrer">
                      <i className={`fa fa-${item.name}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row copy-right mt-md-4 mt-2 mb-2">
            <div className="col-12 text-left text-md-center">
              <img
                width={90}
                height={28}
                className="ftr-logo"
                src="/images/logo-white.svg"
                layout="intrinsic"
                alt="logo-image"
              />
              <p className="mb-0">
                © 2021 Hello Travel Pte Ltd.All Rights Reserved.
              </p>
            </div>
            <div className="col-12 text-left text-md-center cr-text">
              <a
                onClick={() =>
                  handleRedirect({ slug: '/page/end-user-license-agreement' })
                }
              >
                EndUser License Agreement
              </a>
              <span> | </span>
              <Link href="/privacy-policy" target="_blank">
                <a>Privacy Policy</a>
              </Link>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- Footer Ends here --> */}
    </React.Fragment>
  );
}
